import React, { useState, useEffect } from 'react';

import { Text } from '@chakra-ui/react';

export default function Solution({ solution }) {
  const [extended, setExtended] = useState(false);
  const [display, setDisplay] = useState('');

  useEffect(() => {
    let trunced = solution.slice(0, 350);
    setDisplay(trunced);
  }, [solution]);

  const lengthenString = () => {
    extended ? setDisplay(solution.slice(0, 350)) : setDisplay(solution);
    setExtended(!extended);
  };

  return (
    <Text textAlign="center" maxWidth="100ch" margin={2}>
      {display}
      {display.length === solution.length ? null : (
        <span
          style={{
            color: '#c5272f',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={lengthenString}
        >
          {!extended ? <p>Pokaži cijeli case</p> : <p>Smanji case</p>}
        </span>
      )}
    </Text>
  );
}
