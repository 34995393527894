import React, { useState, lazy } from 'react';

import { CaseForm, Form } from './case/index';
import { Button, Spinner, useToast, Stack } from '@chakra-ui/react';

import styles from '../styles/case.module.css';

const CV = lazy(() => import('./case/CV'));

export default function Case() {
  const toast = useToast();

  const [uuid, setUUID] = useState('');
  const [teamNum, setTeamNum] = useState([1, 2, 3]);
  const [files, setFiles] = useState({});
  const [awaiting, setAwaiting] = useState(false);
  const [data, setData] = useState({
    files: {
      0: {},
      1: {},
      2: {},
    },
    strings: {
      0: {
        team: '',
        caseSolution: '',
      },
      1: {
        team: '',
        caseSolution: '',
      },
      2: {
        team: '',
        caseSolution: '',
      },
      uuid: uuid,
    },
  });

  const formData = new FormData();
  if (Object.keys(files).length) {
    files.forEach((v, index) => {
      formData.append(`filesCV${index}`, v);
    });
  }

  if (Object.keys(data.files[0]).length) {
    data.files[0].forEach((v, index) => {
      formData.append(`pr1${index}`, v);
    });
  }
  if (Object.keys(data.files[1]).length) {
    data.files[1].forEach((v, index) => {
      formData.append(`pr2${index}`, v);
    });
  }

  if (Object.keys(data.files[2]).length) {
    data.files[2].forEach((v, index) => {
      formData.append(`pr3${index}`, v);
    });
  }

  formData.append('team_num', JSON.stringify(teamNum.length));

  const handleSubmit = () => {
    formData.append('strings', JSON.stringify(data.strings));

    setAwaiting(true);
    fetch('/api/parse', {
      method: 'POST',
      body: formData,
    }).then((res) => {
      setAwaiting(false);
      if (res.status === 406) {
        res.json().then((d) => {
          toast({
            title: 'Dogodila se greška',
            description: d.error,
            status: 'error',
            isClosable: true,
          });
          setAwaiting(false);
        });
      } else if (res.status === 409) {
        res.json().then((d) => {
          toast({
            title: 'Dogodila se greška',
            description: d.error,
            status: 'error',
            isClosable: true,
          });
        });
      } else if (res.status === 201) {
        res.json().then((d) => {
          toast({
            title: 'Success!',
            description: d.success,
            status: 'success',
            isClosable: true,
          });
        });
      }
    });
  };

  return (
    <Stack className={styles.wrapper} paddingTop={[120]}>
      {!uuid ? (
        <Form uuid={uuid} setUUID={setUUID} setTeamNum={setTeamNum} />
      ) : (
        <>
          <CV setFiles={setFiles} uuid={uuid} setData={setData} data={data}>
            {teamNum.map((x, index) => {
              return (
                <CaseForm
                  index={index}
                  uuid={uuid}
                  filesCV={files}
                  changeData={setData}
                  data={data}
                />
              );
            })}
          </CV>
          <Button
            margin={4}
            minW={150}
            onClick={handleSubmit}
            disabled={awaiting ? true : false}
          >
            {!awaiting ? <p>Predaj Prijavu</p> : <Spinner />}
          </Button>

          <div style={{ margin: '10px 20px' }}>
            Ukoliko imaš poteškoća s predajom prijave obrati se na
            it.podrska@estudent.hr
          </div>
        </>
      )}
    </Stack>
  );
}
