import React, { useEffect } from 'react'
import { Box, Stack, Button } from '@chakra-ui/react'

import { useHistory } from 'react-router-dom'

export default function Items({ isOpen }) {
    let history = useHistory()

    const user = JSON.parse(localStorage.getItem('user'))

    function goToDashboard() {
        history.push('/dashboard')
    }

    function goToOverview() {
        history.push('/overview')
    }

    function handleClick() {
        history.push('/prijava')
    }

    return (
        <Box
            display={{ base: isOpen ? "block" : "none", md: "block" }}
            flexBasis={{ base: "100%", md: "auto" }}
        >
            <Stack
                spacing={8}
                align="center"
                justify={["center", "space-between", "flex-end", "flex-end"]}
                direction={["column", "row", "row", "row"]}
                pt={[4, 4, 0, 0]}
            >
                {user != null ? <Button variant='solid' onClick={goToDashboard}>Dashboard</Button> : null}
                {user != null ? <Button variant='solid' onClick={goToOverview}>Overview</Button> : null}
                <Button variant='solid' onClick={handleClick}>Prijavi se!</Button>
            </Stack>
        </Box>
    )
}
