import React from 'react'

import { BoxItems } from './index'

import {
    Box, Flex, Image,
} from '@chakra-ui/react'


export default function BoxWrapper({ title, icon, description, openModal }) {
    return (
        <>
            <Box maxW="sm" width={300} borderWidth="1px" borderRadius="lg" overflow="hidden" _hover={{
                bg: 'grey',
                transition: '0.1s',
                cursor: 'pointer'
            }}
                onClick={() => openModal(title, icon, description)}
            >
                <Flex justify="center">
                    <div style={{ marginTop: 20 }}>
                        <Image src={icon} width={28} />
                    </div>
                </Flex>
                <BoxItems title={title} />
            </Box>

        </>
    )
}
