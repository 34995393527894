import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Button,
  Text,
  Spinner
} from '@chakra-ui/react';

export default function CandidateList({
  checkApplication,
}) {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true)

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    fetch('/api/dashboard/get_applications/', {
      method: 'POST',
      headers: {
        "Accept": 'application/json',
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem('access')
      },
      body: JSON.stringify({
        uuid: user.id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setApplications(res)
        setLoading(false)
      });
    // eslint-disable-next-line
  }, []);

  return applications.length !== 0 ? (
    <Flex align="center">
      <Table variant="dashboard">
        <Thead borderBottom="1px solid grey">
          <Tr>
            <Td></Td>
            <Td>Ime</Td>
            <Td>Prezime</Td>
            <Td>Spol</Td>
            <Td>Fakultete</Td>
            <Td>Smjer</Td>
            <Td>Godina upisa</Td>
            <Td>Broj mobitela</Td>
            <Td>Grad</Td>
            <Td>Prvi odabir</Td>
            <Td>Drugi odabir</Td>
            <Td>Treći odabir</Td>
            <Td>Prijava</Td>
          </Tr>
        </Thead>
        <Tbody>
          {applications.map((x) => {
            return (
              <Tr>
                <Td>
                  <Avatar />
                </Td>
                <Td>{x.first_name}</Td>
                <Td>{x.last_name}</Td>
                <Td>{x.gender}</Td>
                <Td>{x.university}</Td>
                <Td>{x.course}</Td>
                <Td>{x.year}</Td>
                <Td>{x.pnumber}</Td>
                <Td>{x.city}</Td>
                <Td>{x.first_choice}</Td>
                <Td>{x.second_choice}</Td>
                <Td>{x.third_choice}</Td>
                <Td>
                  {user.is_preds ? (
                    <Button
                      variant="solid"
                      color="white"
                      onClick={() => checkApplication(x.id)}
                    >
                      Pogledaj sve prijave
                    </Button>
                  ) : (
                    <Button
                      variant="solid"
                      color="white"
                      onClick={() => checkApplication(x.id)}
                    >
                      Pogledaj prijavu
                    </Button>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Flex>
  ) : loading ? (
    <Flex justify='center'>
      <Spinner color="#c5272f" size="xl" />
    </Flex>
  ) : (
    <Flex align="center" justify="center">
      <Text paddingTop={100} fontSize={36}>
        Nažalost, još nema prijava za tvoj tim
      </Text>
    </Flex>
  );
}
