import React, { useState, lazy } from 'react';

import { TWriter, DescriptionItem, Sidepanel } from '.';
import { useDisclosure, Flex } from '@chakra-ui/react';
import { TeamsDataList } from './TeamsData';
import styles from '../styles/body.module.css';

const Popup = lazy(() => import('./description/Popup'));

export default function Body() {
  const [title, setTitle] = useState('');
  const [icon, setIcon] = useState('');
  const [description, setDescription] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const openModal = (title, icon, description) => {
    onOpen();
    setTitle(title);
    setIcon(icon);
    setDescription(description);
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      paddingTop={[250, 130, 120]}
    >
      <div className={styles.landing}>
        <div className={styles.writerSection}>
          <TWriter />
        </div>
        <div className={styles.panelSection}>
          <Sidepanel />
        </div>
      </div>
      <div className={styles.teamInformation}>
        {TeamsDataList.map((x) => {
          return (
            <DescriptionItem
              title={x.title}
              icon={x.icon}
              description={x.description}
              openModal={openModal}
            />
          );
        })}
        <Popup
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          title={title}
          icon={icon}
          description={description}
        />
      </div>
    </Flex>
  );
}
