import React, { useState, useEffect } from 'react';

import { useToast, Text, Flex, Spinner } from '@chakra-ui/react';

import { CandidateList } from './selection/index';

export default function Selection() {
  const [lap, setLap] = useState(0);
  const [loading, setLoading] = useState(true);

  const toast = useToast();

  useEffect(() => {
    fetch('/api/lap/get_lap/', {
      headers: {
        'Authorization': localStorage.getItem('access')
      }
    }).then((res) => {
      if (res.status === 410) {
        toast({
          title: 'Info',
          description: 'Izgled da ne postoji krug',
          status: 'info',
          isClosable: true,
        });
        setLoading(false);
      } else {
        res.json().then((res) => {
          setLap(res);
          setLoading(false);
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  return !loading ? (
    <div style={{ paddingTop: 120, maxWidth: '100vw' }}>
      {lap ? (
        <Flex align="flex-end" direction="column">
          <Text fontSize={18} padding={2}>
            {lap.lap_number}. krug odabira članova
          </Text>
          <Text fontSize={18} padding={2}>
            {lap.round}. krug prijava
          </Text>
          <Text fontSize={18} padding={2}>
            Vrijeme početka: {lap.lap_start}
          </Text>
          <Text fontSize={18} padding={2}>
            Vrijeme završetka: {lap.lap_end}
          </Text>
          <Text fontSize={18} padding={2}>
            Krug odabira je: {lap.active ? 'Otvoren' : 'Zatvoren'}
          </Text>
        </Flex>
      ) : (
        <Flex justify="flex-end">
          <Text fontSize={18} padding={2}>
            Krug odabira članova još uvijek nije pokrenut
          </Text>
        </Flex>
      )}
      <CandidateList lap={lap} />
    </div>
  ) : (
    <Flex justify='center'  height='100vh' alignItems='center'>
      <Spinner size='xl' color='#c5272f' />
    </Flex>
  );
}
