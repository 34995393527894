import React, { useState, useEffect } from 'react'
import { Flex, useMediaQuery } from '@chakra-ui/react'

export default function NavigationBar({ children, ...props }) {
  const [bg, setBg] = useState('')
  const [isSmallerThan770] = useMediaQuery("(max-width: 770px)")

  useEffect(() => {
    function checkIfBgUpdate() {
      if (window.scrollY <= 30) {
        setBg('')
      } else {
        setBg('black')
      }
    }
    
    window.addEventListener('scroll', checkIfBgUpdate)

    return () => {
      window.removeEventListener('scroll', checkIfBgUpdate)
    }
  }, [])

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={8}
      p={8}
      transition='0.3s'
      bg={isSmallerThan770 ? 'black' : bg}
      zIndex={5}
      position='fixed'
      {...props}
    >
      {children}
    </Flex>
  )

}
