import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Link,
  Button,
  Text,
} from '@chakra-ui/react';

function Popup({ isOpen, onClose, icon, title, email }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={true}
      allowPinchZoom={true}
    >
      <ModalOverlay />
      <ModalContent
        bg="black"
        border="1px solid #c5272f"
        maxWidth={[335, 450, 550]}
      >
        <ModalHeader color="white" textAlign="center">
          {title}
        </ModalHeader>
        <ModalCloseButton
          color="white"
          _focus={{ border: '1px solid #c5272f' }}
        />
        <Image src={icon} width={14} margin="2" />
        <ModalBody>
          <Text color="white" textAlign="center" marginBottom="2">
            Čestitamo ti na prvom koraku na putu prema izvrsnosti!
            Daljnje upute za prijavu za tim u <span style={{ color: '#c5272f' }}>e</span>STUDENTu ćeš dobiti na e-mail
            adresu <span style={{ fontWeight: 'bold' }}>{email}</span> koja je
            bila priložena prilikom prijave. U slučaju bilo kakvih pitanja možeš
            se obratiti na mail 
            <span style={{ fontWeight: 'bold', color: '#c5272f' }}> it.podrska@estudent.hr</span>
            . Sretno i veselimo se surađivati s tobom u sljedećoj
            <span style={{ color: '#c5272f' }}> e</span>STUDENT godini!
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="solid" color="white" onClick={onClose}>
            <Link href="/">Zatvori</Link>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default Popup;
