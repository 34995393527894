import React from 'react'

import { Form } from './form'

export default function SignUpPage() {
    return (
        <div>
            <Form />
        </div>
    )
}
