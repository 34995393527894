import React, { useState, useEffect } from 'react';

import { useFormik } from 'formik';
import {
  Button,
  Input,
  Stack,
  Text,
  HStack,
  Select,
  Radio,
  RadioGroup,
  useToast,
} from '@chakra-ui/react';
import { generateRandom } from './utils/utils';

import * as Yup from 'yup';

export default function Form() {
  const [preds, setPreds] = useState('');
  const [teams, setTeams] = useState([])

  const formValidation = Yup.object().shape({
    fname: Yup.string()
      .min(2, 'Minimalan broj znakova je 2')
      .max(50, 'Prekoračili ste maksimalan broj znakova')
      .required('Ovo polje je obavezno'),
    lname: Yup.string()
      .min(2, 'Minimalan broj znakova je 2')
      .max(50, 'Prekoračili ste maksimalan broj znakova')
      .required('Ovo polje je obavezno'),
    email: Yup.string()
      .email('Nevažeći email')
      .required('Ovo polje je obavezno'),
    teams: Yup.string()
  });

  const password = generateRandom(10)

  const access = localStorage.getItem('access')

  const toast = useToast();

  useEffect(() => {
      fetch('/api/teams/get_teams/')
      .then(res => res.json())
      .then(res => setTeams(res))
  }, [])

  const formik = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      email: '',
      team: '',
    },
    validationSchema: formValidation,
    onSubmit: (values) => {
      fetch('/api/invite/insert/', {
        method: 'POST',
        headers: {
          "Accept": 'application/json',
          'Content-Type': 'application/json',
          "Authorization": access
        },
        body: JSON.stringify({
          email: values.email,
          first_name: values.fname,
          last_name: values.lname,
          team: values.teams,
          is_preds: preds,
          password: password,
        }),
      }).then((res) => {
        if (res.status === 201) {
          toast({
            title: 'Success!',
            description: 'Prijava je uspiješna!',
            status: 'success',
            isClosable: true,
          });
        } else if (res.status === 401) {
          toast({
            title: `${res.status} - Dogodila se greška`,
            description:
              'Nemate dovoljno velika ovlaštenja za pozivanje korisnika',
            status: 'error',
            isClosable: true,
          });
        } else {
          toast({
            title: `Dogodila se greška`,
            description:
              'Greška sa serverom. Kontaktirajte it.podrska@estudent.hr',
            status: 'error',
            isClosable: true,
          });
        }
      });
    },
  });

  return (
    <div style={{ paddingTop: 100 }}>
      <form onSubmit={formik.handleSubmit}>
        <HStack justify="center" align="center">
          <Stack justify="center" align="center">
            <Text>Ime</Text>
            <Input
              size="md"
              variant="filled"
              onChange={formik.handleChange}
              name="fname"
              type="text"
              value={formik.values.fname}
              w={[300, 425]}
            />
            {formik.errors.fname && formik.touched.fname ? (
              <Text variant="erroring">{formik.errors.fname}</Text>
            ) : null}
            <Text>Prezime</Text>
            <Input
              size="md"
              variant="filled"
              onChange={formik.handleChange}
              name="lname"
              type="text"
              value={formik.values.lname}
              w={[300, 425]}
            />
            {formik.errors.lname && formik.touched.lname ? (
              <Text variant="erroring">{formik.errors.lname}</Text>
            ) : null}
            <Text>Adresa <span style={{ color: '#c5272f' }}>e</span>STUDENT e-pošte</Text>
            <Input
              size="md"
              variant="filled"
              onChange={formik.handleChange}
              name="email"
              type="text"
              value={formik.values.email}
              w={[300, 425]}
            />
            {formik.errors.email && formik.touched.email ? (
              <Text variant="erroring">{formik.errors.email}</Text>
            ) : null}

            <Text>Tim koji osoba vodi</Text>
            <Select
              size="md"
              variant="filled"
              placeholder="Odaberi broj timova"
              onChange={formik.handleChange}
              name="teams"
              value={formik.values.teams}
              w={[300, 425]}
            >
                {teams.map(x => <option>{x.name}</option>)}
            </Select>
            {formik.errors.teams && formik.touched.teams ? (
              <Text variant="erroring">{formik.errors.teams}</Text>
            ) : null}
            <Text>Da li je osoba član Predsjedništva</Text>
            <RadioGroup
              onChange={setPreds}
              name="preds"
              type="radio"
              value={preds}
            >
              <Stack direction="row">
                <Radio value="true">Da</Radio>
                <Radio value="false">Ne</Radio>
              </Stack>
            </RadioGroup>
            {formik.errors.preds && formik.touched.preds ? (
              <Text variant="erroring">{formik.errors.preds}</Text>
            ) : null}
            <Button type="submit">Unesi korisnika</Button>
          </Stack>
        </HStack>
      </form>
    </div>
  );
}
