import React, { useState, lazy } from 'react';

import { useFormik } from 'formik';
import {
  Button,
  Input,
  Stack,
  Text,
  HStack,
  Select,
  InputLeftAddon,
  InputGroup,
  Radio,
  RadioGroup,
  useDisclosure,
  useToast,
  Flex,
  Link
} from '@chakra-ui/react';
import { generateRandom } from '../utils/utils.js'

import * as Yup from 'yup';

const Popup = lazy(() => import('./Popup'))

export default function Form() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [gender, setGender] = useState('other');
  // eslint-disable-next-line
  const [disabled, setDisabled] = useState(false);

  const formValidation = Yup.object().shape({
    fname: Yup.string()
      .min(2, 'Minimalan broj znakova je 2')
      .max(50, 'Prekoračili ste maksimalan broj znakova')
      .required('Ovo polje je obavezno'),
    lname: Yup.string()
      .min(2, 'Minimalan broj znakova je 2')
      .max(50, 'Prekoračili ste maksimalan broj znakova')
      .required('Ovo polje je obavezno'),
    email: Yup.string()
      .email('Nevažeći email')
      .required('Ovo polje je obavezno'),
    pnumber: Yup.string()
      .min(6, 'Broj mobitela ima premalo znakova')
      .max(12, 'Broj mobitela ima previše znakova')
      .required('Ovo polje je obavezno'),
    city: Yup.string().required('Ovo polje je obavezno'),
    uni: Yup.string().required('Ovo polje je obavezno'),
    year: Yup.number().required('Ovo polje je obavezno'),
    course: Yup.string().required('Ovo polje je obavezno'),
    teams: Yup.number().required('Ovo polje je obavezno'),
  });

  const toast = useToast();

  const password = generateRandom(10)

  const formik = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      email: '',
      city: '',
      pnumber: '',
      year: 1,
      uni: '',
      course: '',
      teams: 1,
    },
    validationSchema: formValidation,
    onSubmit: (values) => {
      fetch('/auth/users/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formik.values.email,
          first_name: formik.values.fname,
          last_name: formik.values.lname,
          team_num: formik.values.teams,
          gender: gender,
          pnumber: formik.values.pnumber,
          year: formik.values.year,
          city: formik.values.city,
          university: formik.values.uni,
          course: formik.values.course,
          is_estudent: false,
          password: password,
        }),
      }).then((res) => {
        if (res.status === 201) {
          toast({
            title: 'Success!',
            description: 'Prijava je uspiješna!',
            status: 'success',
            isClosable: true,
          });
         onOpen()
        } else if (res.status !== 500) {
          toast({
            title: 'Dogodila se greška',
            description:
              'Izgleda da već postoji prijava s tim e-mailom. Ako smatraš da je ovo greška kontaktiraj nas na it.podrska@estudent.hr',
            status: 'error',
            isClosable: true,
          });
        }
      });
    },
  });

  return !disabled ? (
    <Stack paddingTop={[250, 160, 120]}>
      <form onSubmit={formik.handleSubmit}>
        <HStack justify="center" align="center">
          <Stack justify="center" align="center">
            <Text>Ime</Text>
            <Input
              size="md"
              variant="filled"
              onChange={formik.handleChange}
              name="fname"
              type="text"
              value={formik.values.fname}
              w={[300, 425]}
            />
            {formik.errors.fname && formik.touched.fname ? (
              <Text variant="erroring">{formik.errors.fname}</Text>
            ) : null}
            <Text>Prezime</Text>
            <Input
              size="md"
              variant="filled"
              onChange={formik.handleChange}
              name="lname"
              type="text"
              value={formik.values.lname}
              w={[300, 425]}
            />
            {formik.errors.lname && formik.touched.lname ? (
              <Text variant="erroring">{formik.errors.lname}</Text>
            ) : null}
            <Text>Spol</Text>
            <RadioGroup
              onChange={setGender}
              name="gender"
              type="radio"
              value={gender}
              _focus={{
                color: '#c5272f'
              }}
            >
              <Stack direction="row">
                <Radio value="male">Muško</Radio>
                <Radio value="female">Žensko</Radio>
                <Radio value="other">Ostalo</Radio>
              </Stack>
            </RadioGroup>
            {formik.errors.gender && formik.touched.gender ? (
              <Text variant="erroring">{formik.errors.gender}</Text>
            ) : null}
            <Text>Adresa privatne e-pošte</Text>
            <Input
              size="md"
              variant="filled"
              onChange={formik.handleChange}
              name="email"
              type="text"
              value={formik.values.email}
              w={[300, 425]}
            />
            {formik.errors.email && formik.touched.email ? (
              <Text variant="erroring">{formik.errors.email}</Text>
            ) : null}
            <Text>Broj mobitela</Text>
            <InputGroup w={[300, 425]}>
              <InputLeftAddon children="+385" />
              <Input
                size="md"
                variant="filled"
                onChange={formik.handleChange}
                name="pnumber"
                type="text"
                value={formik.values.pnumber}
              />
            </InputGroup>
            {formik.errors.pnumber && formik.touched.pnumber ? (
              <Text variant="erroring">{formik.errors.pnumber}</Text>
            ) : null}
            <Text>Grad studiranja</Text>
            <Select
              size="md"
              variant="filled"
              placeholder="Odaberi grad studiranja"
              onChange={formik.handleChange}
              name="city"
              value={formik.values.city}
              w={[300, 425]}
            >
              <option value="Zagreb">Zagreb</option>
              <option value="Varaždin">Varaždin</option>
            </Select>
            <Text>Fakultet</Text>
            <Input
              size="md"
              variant="filled"
              onChange={formik.handleChange}
              name="uni"
              type="text"
              value={formik.values.uni}
              w={[300, 425]}
            />
            {formik.errors.uni && formik.touched.uni ? (
              <Text variant="erroring">{formik.errors.uni}</Text>
            ) : null}
            <Text>Godina prvog upisa</Text>
            <Input
              size="md"
              variant="filled"
              onChange={formik.handleChange}
              name="year"
              type="number"
              value={formik.values.year}
              w={[300, 425]}
            />
            {formik.errors.year && formik.touched.year ? (
              <Text variant="erroring">{formik.errors.year}</Text>
            ) : null}
            <Text>Naziv smjera studija</Text>
            <Input
              size="md"
              variant="filled"
              onChange={formik.handleChange}
              name="course"
              type="text"
              value={formik.values.course}
              w={[300, 425]}
            />
            {formik.errors.course && formik.touched.course ? (
              <Text variant="erroring">{formik.errors.course}</Text>
            ) : null}
            <Text>Broj timova za koji se prijavljuješ</Text>
            <Select
              size="md"
              variant="filled"
              placeholder="Odaberi broj timova"
              onChange={formik.handleChange}
              name="teams"
              value={formik.values.teams}
              w={[300, 425]}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </Select>
            {formik.errors.teams && formik.touched.teams ? (
              <Text variant="erroring">{formik.errors.teams}</Text>
            ) : null}
            <Link href='https://docs.google.com/document/d/1yNqN24mCzsgsjg3_Q9gUOcbxn4yqPgck11bwXZLMMk0/edit' textDecor='underline' target="_blank" rel="noopener noreferrer">GDPR privola</Link>
            <Button type="submit">Sljedeći korak</Button>
            <Text fontSize={12} padding={2}>Klikom na gumb <i>Sljedeći korak</i> prihvaćate uvjete GDPR privole</Text>
          </Stack>
        </HStack>
      </form>
      <Popup
        isOpen={isOpen}
        onClose={onClose}
        title="Prijava uspješna"
        email={formik.values.email}
      />
    </Stack>
  ) : (
    <Flex paddingTop={120}  align='center' justify='center'>
      <Text fontSize={48}>
        Prijave za <span>e</span>STUDENT pristupni proces nisu još počele
      </Text>
    </Flex>
  );
}
