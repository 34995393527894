import React, { useEffect, useState } from 'react'

import { Flex, Table, Thead, Td, Tr, Tbody, Avatar } from '@chakra-ui/react'

export default function Accepted() {
    const [applications, setApplications] = useState([])

    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        fetch('/api/members/accepted/', {
            headers: {
                'Authorization': localStorage.getItem('access'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                uuid: user.id,
              }),
        })
        .then(res => res.json())
        .then(res => setApplications(res))
    }, [])
                
    return applications.length === 0 ? null : (
        <Flex align="center" paddingTop={[250, 130, 120]}>
        <Table variant="dashboard">
          <Thead borderBottom="1px solid grey">
            <Tr>
              <Td></Td>
              <Td>Ime</Td>
              <Td>Prezime</Td>
              <Td>Spol</Td>
              <Td>Fakultete</Td>
              <Td>Smjer</Td>
              <Td>Godina upisa</Td>
              <Td>Broj mobitela</Td>
              <Td>Grad</Td>
              <Td>Prihvaćen tim</Td>
            </Tr>
          </Thead>
          <Tbody>
            {applications.map((x) => {
              return (
                <Tr>
                  <Td>
                    <Avatar />
                  </Td>
                  <Td>{x.first_name}</Td>
                  <Td>{x.last_name}</Td>
                  <Td>{x.gender}</Td>
                  <Td>{x.university}</Td>
                  <Td>{x.course}</Td>
                  <Td>{x.year}</Td>
                  <Td>{x.pnumber}</Td>
                  <Td>{x.city}</Td>
                  <Td>{x.accepted_team}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Flex>
    )
}
