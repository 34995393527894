import React, { useState, useRef } from 'react'
import { MdFileUpload } from 'react-icons/md'
import { BsTrash } from 'react-icons/bs'
import nodata from '../../static/images/gone.svg'
import cv from '../../static/images/cv.svg'

import styles from '../../styles/filecv.module.css'

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10000000;
const KILO_BYTES_PER_BYTE = 1000;

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

export default function FileUpload({
    label = '',
    updateFileCb,
    maxFileSize = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    ...otherProps
}) {
    const inputField = useRef(null)
    const [files, setFiles] = useState({})

    const handleUploadBtnClick = () => {
        inputField.current.click();
    }

    const handleNewFileUpload = (e) => {
        const { files: newFiles } = e.target;
        if (newFiles.length) {
            let updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles)
            callUpdateFileCb(updatedFiles)
        }
    }

    const addNewFiles = newFiles => {
        for (let file of newFiles) {
            if (file.size <= maxFileSize) {
                if (!otherProps.multiple) {
                    return { file }
                }
                files[file.name] = file
            }
        }
        return { ...files }
    }

    const convertNestedObjectToArray = (nestedObj) =>
        Object.keys(nestedObj).map((key) => nestedObj[key]);

    const callUpdateFileCb = (files) => {
        const filesAsArray = convertNestedObjectToArray(files);
        updateFileCb(filesAsArray);
    };

    const removeFile = (fileName) => {
        delete files[fileName];
        setFiles({ ...files });
        callUpdateFileCb({ ...files });
    };

    return (
        <div className={styles.containerWrapper}>
            <section className={styles.inputSection}>
                <p className={styles.dragText}>Povucite i otpustite datoteke bilo gdje unutar kutije:</p>
                <button type="button" className={styles.buttonInput} onClick={handleUploadBtnClick}>
                    <MdFileUpload className={styles.icon} />
                    <span> Predaj {otherProps.multiple ? "dokumente" : "dokument"}</span>
                </button>
                <input
                    type="file"
                    ref={inputField}
                    onChange={handleNewFileUpload}
                    title=""
                    value=""
                    {...otherProps}
                    className={styles.fileInput}
                />
            </section>
            <article className={styles.fileShell}>
                <span style={{ padding: 10 }}>Predani dokumenti:</span>
                <div className={styles.uploadedWrapper}>
                    {Object.keys(files).length === 0 ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <img alt='no document' src={nodata} style={{ width: 150, padding: 10 }} />
                            <p style={{ padding: 20, fontSize: 16, textAlign: 'center', width: 300 }}>Izgleda da nisi predao ni jedan dokument. Klikni na <span style={{ fontWeight: 'bold', color: '#c5272f' }} >Predaj dokumente</span> ili povuci dokumente u dio za predaju</p>
                        </div>
                        :
                        <>
                            {Object.keys(files).map((fileName, index) => {
                                let file = files[fileName];
                                return (
                                    <div className={styles.imageSection}>
                                            <img
                                                alt='supplied document'
                                                className={styles.imagePreview}
                                                src={cv}
                                            />
                                        <div>
                                            <span className={styles.imageText}>{file.name}</span>
                                            <aside>
                                                <span className={styles.imageSize}>{convertBytesToKB(file.size)} kb</span>
                                            </aside>
                                        </div>
                                        <BsTrash size={24} className={styles.deleteIcon} onClick={() => removeFile(fileName)} />
                                    </div>
                                );
                            })}
                        </>
                    }

                </div>
            </article>
        </div>
    )
}
