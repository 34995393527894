export function generateRandom(len) {
    const charset = "abcdefghqwertzknhkjn214125".split("");
    const str = [...Array(len)].map(
      () => charset[~~(Math.random() * charset.length)]
    );

    return str.join("");
  }

export function storeUser(access, refresh, user) {
    localStorage.setItem('access', access)
    localStorage.setItem('refresh', refresh)
    localStorage.setItem('user', JSON.stringify(user))
}