import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Link,
  Button,
  Text,
  Divider
} from '@chakra-ui/react';

function Popup({ isOpen, onClose, application }) {
  return application.length !== 0 ? (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={true}
      allowPinchZoom={true}
    >
      <ModalOverlay />
      <ModalContent
        bg="black"
        border="1px solid #c5272f"
        maxWidth={[360, 450, 550]}
      >
        {application.map((x) => {
          return (
            <>
            <ModalHeader color="white" textAlign="center">
              Prijava za tim {x.team_id}
            </ModalHeader>
            <ModalCloseButton
              color="white"
              _focus={{ border: '1px solid #c5272f' }}
            />
            <Text color="white" fontSize={22}>
              Ime: {x.user_id}
            </Text>
            <ModalBody textAlign="center" style={{paddingLeft: '10px', paddingRight: '10px'}}>
              <Text color="white" fontSize={18}>
                Rješenje case-a
              </Text>
              <Text color="white" textAlign="center" marginBottom="2" style={{width: '100%', wordBreak: 'break-word', hyphens: 'auto'}}>
                {x.case_solution}
              </Text>
              <Text color="white" fontSize={18}>
                Link na datoteke za case
              </Text>
              <Link
                href={x.drive_url}
                target="_blank"
                color="white"
                textAlign="center"
                marginBottom="2"
                style={{width: '100%', wordBreak: 'break-all', hyphens: 'none'}}
              >
                {x.drive_url}
              </Link>
            </ModalBody>
            <Divider />
          </>
          )
    
        })}
        <ModalFooter>
          <Button variant="solid" color="white" onClick={onClose}>
            <Text>Zatvori</Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : null;
}

export default Popup;
