import React from 'react'

import { Text } from '@chakra-ui/react'
import { Typewriter } from 'react-simple-typewriter'

export default function TWriter() {
    return (
        <Text
        fontSize={[30, 60, 70, 70]}
        color="white"
        display='flex'
        justifyContent='center'
        >
          <Typewriter
            words={['Timski rad', 'Networking', 'Pravo na pogrešku', 'Edukacija', 'Ravnopravnost', 'Poštovanje', 'Humanitarnost', 'Empatija', 'Autentičnost', 'Proaktivnost', 'Inovativnost', 'Svestranost']}
            loop={false}
            cursor
            cursorStyle='_'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
        </Text>
    )
}
