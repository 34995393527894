import React, { useState } from 'react'
import { MenuButton, Items, Logo, NavigationBar } from './navigation/index'

export default function Navigation(props) {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => setIsOpen(!isOpen)

    return (
        <NavigationBar {...props}>
            <Logo />
            <MenuButton isOpen={isOpen} toggle={toggle} />
            <Items isOpen={isOpen} />
        </NavigationBar>

    )
}
