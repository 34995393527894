import { lazy, Suspense } from 'react';

import { Navigation, Body } from './components/index';

import mreza from './static/backgrounds/mreza.png';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import '@fontsource/poppins';

const SignUpPage = lazy(() => import('./components/SignUpPage'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const Selection = lazy(() => import('./components/Selection'));
const Login = lazy(() => import('./components/Login'));
const Invitation = lazy(() => import('./components/Invitation'));
const Case = lazy(() => import('./components/Case'));
const Priority = lazy(() => import('./components/Priority'));
const Overview = lazy(() => import('./components/Overview'));
const Finished = lazy(() => import('./components/Finished'));
const Accepted = lazy(() => import('./components/Accepted'));

const theme = extendTheme({
  fonts: {
    body: 'Poppins',
    heading: 'Poppins',
  },
  components: {
    Text: {
      variants: {
        erroring: {
          fontWeight: 'bold',
          color: '#c5272f',
        },
        stopwatch: {
          fontSize: 48,
        },
      },
    },
    Table: {
      variants: {
        dashboard: {
          table: {
            bg: 'white',
            color: 'black',
          },
          td: {
            textAlign: 'center',
            margin: 0,
            padding: 3,
          },
        },
      },
    },
    Button: {
      variants: {
        solid: {
          bg: '#c5272f',
          _hover: {
            border: '1px solid white',
            bg: '#c5272f',
          },
          _focus: {
            boxShadow: '0 0 0 1px white',
          },
        },
        selection: {
          color: 'white',
          bg: '#c5272f',
        },
        filter: {
          margin: 2,
          color: 'white',
          bg: '#c5272f',
        },
      },
    },
    Input: {
      baseStyle: {
        addon: {
          color: 'black',
        },
      },
      variants: {
        filled: {
          field: {
            color: 'black',
            bg: 'white',
            _focus: {
              borderColor: '#c5272f',
              bg: 'white',
              caretColor: 'black',
              color: 'black',
            },
          },
        },
      },
    },
    Select: {
      variants: {
        filled: {
          field: {
            color: 'black',
            bg: 'white',
            borderColor: 'white',
            _focus: {
              bg: 'white',
              color: 'black',
              borderColor: '#c5272f',
            },
            _hover: {
              bg: 'white',
              color: 'black',
            },
          },
          icon: {
            color: 'black',
          },
        },
        teamSelect: {
          field: {
            color: 'white',
            bg: 'black',
            border: '1px solid white',
            _focus: {
              bg: 'black',
              color: 'white',
              borderColor: '#c5272f',
            },
            _hover: {
              bg: 'black',
              color: 'white',
            },
          },
          icon: {
            color: 'white',
          },
        },
      },
      option: {
        baseStyle: {
          bg: 'black',
        },
      },
    },
    Radio: {
      baseStyle: {},
    },
    InputLeftAddon: {
      baseStyle: {
        field: {
          color: 'black',
        },
      },
    },
  },
});

function App() {
  function PrivateRoute({ children, location, ...rest }) {
    const user = JSON.parse(localStorage.getItem('user'));

    if (!user) {
      return <Redirect to="/authenticate" />;
    }

    return (
      <Route
        {...rest}
        render={({ location }) =>
          user ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  function FinishedRoute() {
    return <Redirect to="/finished" />;
  }

  return (
    <ChakraProvider theme={theme}>
      <div
        style={{
          backgroundColor: 'black',
          backgroundImage: `url(${mreza})`,
          color: 'white',
          minHeight: '100vh',
          position: 'relative',
        }}
      >
        <Router>
          <Navigation />
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/">
                <Body />
              </Route>
              <FinishedRoute path="/prijava">
                <SignUpPage />
              </FinishedRoute>
              <FinishedRoute path="/prijava-drugikorak">
                <Case />
              </FinishedRoute>
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute path="/selection" component={Selection} />
              <PrivateRoute path="/invitation" component={Invitation} />
              <PrivateRoute path="/overview" component={Overview} />
              <PrivateRoute path="/accepted" component={Accepted} />
              <Route path="/priority" component={Priority} />
              <Route path="/authenticate">
                <Login />
              </Route>
              <Route path="/finished" component={Finished} />
            </Switch>
          </Suspense>
        </Router>
      </div>
    </ChakraProvider>
  );
}

export default App;
