import React from 'react'

import { useFormik } from 'formik'
import { Button, Input, Stack, Text, HStack, useToast } from '@chakra-ui/react'

import * as Yup from 'yup'

export default function Form({ setUUID, setTeamNum }) {
    const formValidation = Yup.object().shape({
        passcode: Yup.string().required('Ovo polje je obavezno').trim().test('len' ,'Šifra mora biti točno 36 znakova', val => !val || (val && val.toString().length === 36))
    })

    const toast = useToast()

    const formik = useFormik({
        initialValues: {
            passcode: ''
        },
        validationSchema: formValidation,
        onSubmit: (values) => {
            fetch('/api/members/validate_uuid/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    uuid: formik.values.passcode
                })
            })
                
                .then(res => {
                    if (res.status === 202) {
                        res.json().then(data => {
                            setUUID(formik.values.passcode)
                            setTeamNum(new Array(parseInt(data)).fill('x'))
                        })
                    } else if (res.status !== 500) {
                        toast({
                            title: 'Dogodila se greška',
                            description: 'Izgleda da upisana šifra nije validna. U slučaju da smatrate da je ovo greška, javite se na it.podrska@estudent.hr',
                            status: 'error',
                            isClosable: true,
                        })
                    } else {
                        toast({
                            title: 'Greška servera',
                            description: 'Whoops. Izgleda da se nešto slomilo. Naši informatičari rade naporno da otklone problem. Hvala na strpljenju!',
                            status: 'info'
                        })
                    }
                })
        }
    })

    return (
        <div style={{ paddingTop: 100 }}>
            <form onSubmit={formik.handleSubmit}>
                <HStack justify='center' align='center' h='75vh'>
                    <Stack justify='center' align='center' >
                        <Text>Šifra prijave</Text>
                        <Input size='md' variant='filled' onChange={formik.handleChange} name='passcode' type='text' value={formik.values.passcode} w={[300, 425]} />
                        {formik.errors.passcode && formik.touched.passcode ? (
                            <Text variant='erroring'>{formik.errors.passcode}</Text>
                        ) : null}
                        <Button type='submit'>
                            Sljedeći korak
                        </Button>
                    </Stack>
                </HStack>
            </form>
        </div>
    )
}
