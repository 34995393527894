import React from 'react'

import styles from '../styles/sidepanel.module.css'

import { Text } from '@chakra-ui/react'

export default function Sidepanel() {
    return (
        <div className={styles.panel}>
            {/* <Text
            padding={5}
            fontSize={20}
            >
                Prijave za <span style={{ fontWeight: 'bold' }}>2022./2023.</span> <span style={{ color: "#c5272f" }}>e</span>STUDENT godinu su zatvorene! Hvala svima koji su se prijavili!
            </Text> */}
            <Text
            padding={5}
            fontSize={20}
            >
                Prijave za <span style={{ fontWeight: 'bold' }}>2023./2024.</span> <span style={{ color: "#c5272f" }}>e</span>STUDENT godinu su otvorene!
            </Text>
        </div>
    )
}
