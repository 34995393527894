import React, { useState, useEffect } from 'react';
import { Text, useDisclosure, Stack } from '@chakra-ui/react';
import { CandidateList, Popup, ExportExcel } from './dashboard/index';

export default function Dashboard() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [application, setApplication] = useState([]);
  const [admissions, setAdmissions] = useState(0);

  const user = JSON.parse(localStorage.getItem('user'));

  const checkApplication = (uuid) => {
    onOpen();
    fetch('/api/dashboard/check_application/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('access'),
      },
      body: JSON.stringify({
        uuid: uuid,
      }),
    })
      .then((res) => res.json())
      .then((res) => setApplication(res));
  };

  useEffect(() => {
    fetch('api/dashboard/get_admissions/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('access'),
      },
      body: JSON.stringify({
        uuid: user.id,
      }),
    })
      .then((res) => res.json())
      .then((res) => setAdmissions(res));
  }, [user.id]);

  return admissions.length !== 0 ? (
    <Stack paddingTop={[140, 160, 120]}>
      <Text
        padding="2"
        fontWeight="bold"
        fontSize={32}
        style={{ marginLeft: '10px' }}
      >
        Prijave za {user.is_preds ? 'cijelu udrugu' : `tim ${user.leads}`}
      </Text>
      <ExportExcel applicationsData={admissions} />
      <Text fontSize={24} padding="2" style={{ marginLeft: '10px' }}>
        Broj prijava: {admissions.length}
      </Text>

      <div style={{ overflowX: 'scroll' }}>
        <CandidateList checkApplication={checkApplication} />
      </div>
      <Popup isOpen={isOpen} onClose={onClose} application={application} />
    </Stack>
  ) : (
    <Stack paddingTop={[140, 160, 120]}>
      <Text fontSize={36} fontWeight={900} padding={20}>
        Još nema prijava
      </Text>
    </Stack>
  );
}
