import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { storeUser } from './utils/utils.js'

import { Input, Text, Button, Flex } from '@chakra-ui/react';

export default function Login() {
  const yupSchema = yup.object().shape({
    username: yup.string().required('').min(4, 'Username is too short'),
    password: yup.string().required('').min(8, 'Password is too short'),
  });

  const toast = useToast()
  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: yupSchema,
    onSubmit: (values) => {
        fetch('/api/authenticate/verify/', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: values.username,
            password: values.password.trim(),
          }),
        }).then((res) => {
          if (res.status === 401) {
            res.json().then((r) => {
              toast({
                title: 'Unauthorized',
                description: r.error,
                status: 'error',
                isClosable: true,
              });
            });
          } else {
            res.json().then((r) => {
              storeUser(r.access, r.refresh, r.user);
              history.push('/dashboard');
            });
          }
        });
    },
  });


  return (
    <Flex
      paddingTop={120}
      align="center"
      justify="center"
      flexDirection="column"
    >
      
      <Text fontSize={24}>
        <span style={{ color: '#c5272f' }}>e</span>STUDENT e-mail:
      </Text>
      <Input
        size="md"
        variant="filled"
        onChange={formik.handleChange}
        name="username"
        type="text"
        margin={5}
        value={formik.values.username}
        w={[300, 425]}
      />
      {formik.errors.username && formik.touched.username ? (
        <Text variant="erroring">{formik.errors.username}</Text>
      ) : null}
      <Text fontSize={24}>Lozinka:</Text>

      <Input
        size="md"
        variant="filled"
        onChange={formik.handleChange}
        name="password"
        type="passwordn"
        value={formik.values.password}
        margin={5}
        w={[300, 425]}
      />
      {formik.errors.password && formik.touched.password ? (
        <Text variant="erroring">{formik.errors.password}</Text>
      ) : null}
      <Button variant="solid" type='submit' onClick={formik.handleSubmit}>
        Prijavi se
      </Button>
    </Flex>
  );
}
