import React from 'react'
import { BoxWrapper } from './description'

import { Flex } from '@chakra-ui/react'

export default function DescriptionItem({ title, icon, description, openModal }) {
    return (
        <Flex padding={2}>
            <BoxWrapper title={title} icon={icon} description={description} openModal={openModal}/>
        </Flex>
    )
}
