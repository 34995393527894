import React from 'react';
import * as XLSX from 'xlsx';
import { Button } from '@chakra-ui/react';

export default function ExportExcel({ applicationsData }) {
  const DownloadExcel = () => {
    console.log(applicationsData);

    const rows = applicationsData.map((data) => ({
      name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      pnumber: data.pnumber,
      city: data.city,
      university: data.university,
      course: data.course,
      year: data.year,
      first_choice: data.first_choice,
      second_choice: data.second_choice,
      third_choice: data.third_choice,
    }));

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');

    // customize header names
    XLSX.utils.sheet_add_aoa(worksheet, [
      [
        'Ime',
        'Prezime',
        'e-mail adresa',
        'mobitel',
        'grad',
        'fakultet',
        'smjer',
        'godina upisa',
        'prvi odabir',
        'drugi odabir',
        'treći odabir',
      ],
    ]);

    XLSX.writeFile(workbook, 'PrijaveTima.xlsx', { compression: true });
  };

  return (
    <Button style={{ marginLeft: '10px' }} width={300} onClick={DownloadExcel}>
      Preuzmi tablicu s prijavama
    </Button>
  );
}
