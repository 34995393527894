import React from 'react'

import { Text } from '@chakra-ui/react'

export default function BoxItems({ title }) {
    return (
        <Text paddingBottom={10} paddingLeft={10} paddingRight={10} paddingTop={5} fontWeight="extrabold" fontSize={22} textAlign="center">
            {title}
        </Text>
    )
}
