import React from 'react'

import { Text } from "@chakra-ui/react"

export default function Finished() {
    return (
        <div style={{ paddingTop: 120, display: "flex", alignItems: "center", justifyContent:"center" }}>
            <Text padding={2} fontSize={48}>Prijave za <span style={{ color: "#c5272F" }}>e</span>STUDENT godinu su završene!</Text>
        </div>
    )
}
