import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import {
  HStack,
  Stack,
  Text,
  Select,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import parse from 'html-react-parser';

import { FileUpload } from './index';

export default function CaseForm({ index, changeData, data }) {
  const caseSchema = Yup.object().shape({
    team: Yup.string().required('Ovo polje je obavezno!'),
    caseSolution: Yup.string().required('Ovo polje je obavezno!'),
  });

  const toast = useToast();

  const [file, setFile] = useState({});
  const [allTeams, setAllTeams] = useState('');
  const [team, setTeam] = useState({});

  const formik = useFormik({
    initialValues: {
      team: '',
      caseSolution: '',
    },
    validationSchema: caseSchema,
  });

  const handleChange = (fieldUpdated, value) => {
    formik.setFieldValue(fieldUpdated, value);
    let newData = {};

    if (fieldUpdated === 'team') {
      if (value === '') {
        newData = { ...data };
        toast({
          title: 'Info',
          description: 'Tim ne može biti prazan. Molim te da odabereš tim',
          status: 'info',
          isClosable: true,
        });
      } else {
        const team = allTeams.filter((x) => x.name === value);
        setTeam(team[0]);

        newData = {
          ...data,
          strings: {
            ...data.strings,
            [index]: {
              ...data.strings[index],
              team: value,
            },
          },
        };
      }
    } else if (fieldUpdated === 'caseSolution') {
      newData = {
        ...data,
        strings: {
          ...data.strings,
          [index]: {
            ...data.strings[index],
            caseSolution: value,
          },
        },
      };
    } else {
      newData = {
        ...data,
        files: {
          ...data.files,
          [index]: value,
        },
      };
    }

    changeData(newData);
  };

  useEffect(() => {
    fetch('/api/teams/get_teams/')
      .then((res) => res.json())
      .then((res) => setAllTeams(res));
  }, []);

  const updateFile = (files) => {
    setFile(files);
    handleChange('files', files);
  };

  return Object.keys(allTeams).length !== 0 ? (
    <form
      style={{ paddingTop: 20, minWidth: 250, maxWidth: 550, margin: 10 }}
      onSubmit={formik.handleSubmit}
    >
      <Text padding="0px 10px 15px 10px" fontSize={22} fontWeight="700">
        {index + 1}. prioritet
      </Text>
      <HStack>
        <Stack border="1px solid white" borderRadius={2}>
          <Text padding={3} borderBottom="1px solid white">
            Tim:
          </Text>
          <Select
            variant="teamSelect"
            padding={2}
            name="team"
            color="white"
            bg="black"
            value={formik.values.team}
            onChange={(value) => handleChange('team', value.target.value)}
          >
            <option value="">Odaberi tim</option>
            {allTeams.map((x) => {
              return (
                <option value={x.name} style={{ backgroundColor: 'black' }}>
                  {x.name}
                </option>
              );
            })}
          </Select>
          {formik.errors.team ? (
            <Text variant="erroring" paddingLeft={5}>
              {formik.errors.team}
            </Text>
          ) : null}
          <Text padding={3} borderBottom="1px solid white">
            Case za pristupni:
          </Text>
          <Text padding={5}>
            {Object.keys(team).length !== 0 ? (
              <p>{parse(team.case)}</p>
            ) : (
              <p>Ovdje ide case tima</p>
            )}
          </Text>
          <Text padding={3} borderBottom="1px solid white">
            Rješenje za case:
          </Text>
          <div
            style={{
              padding: 0,
              marginTop: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Textarea
              width="95%"
              name="caseSolution"
              marginBottom={5}
              value={formik.values.caseSolution}
              onChange={(value) =>
                handleChange('caseSolution', value.target.value)
              }
              _focus={{
                borderColor: '#c5272f',
              }}
            />
            {formik.errors.caseSolution ? (
              <Text variant="erroring" paddingTop={3} marginBottom={5}>
                {formik.errors.caseSolution}
              </Text>
            ) : null}
          </div>
          {Object.keys(team).length !== 0 && team.files ? (
            <>
              <Text padding={3} borderBottom="1px solid white">
                Dokumenti za case:
              </Text>
              <FileUpload
                accept={team.formats}
                label="Case file"
                updateFileCb={updateFile}
                multiple="yes"
              />
              <Text paddin={2} fontSize={14}>
                Maksimalna veličina datoteke je 10Mb
              </Text>
              <Text padding={2} fontSize={14}>
                Tim prima format:{' '}
                <span style={{ fontWeight: 900, fontSize: 18 }}>
                  {team.formats}
                </span>
              </Text>
            </>
          ) : null}
        </Stack>
      </HStack>
    </form>
  ) : null;
}
