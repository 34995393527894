import React, { useEffect, useState } from 'react';

import { Counter, Teams, Unique } from './overview/index';

import { Flex } from '@chakra-ui/react';

export default function Overview() {
  const [count, setCount] = useState({
    first_form: 0,
    second_form: 0,
    student_number: 0,
  });

  const [applications, setApplications] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [countT, setCountT] = useState([])

  useEffect(() => {
    fetch('/api/overview/fetch_all/')
      .then((res) => res.json())
      .then((res) => setCount(res));
  }, []);

  useEffect(() => {
    fetch('/api/overview/application_per_teams/')
      .then((res) => res.json())
      .then((res) => {
        setApplications(res);
        setFiltered(res);
      });
  }, []);

  useEffect(() => {
    fetch('/api/overview/get_placements/')
      .then((res) => res.json())
      .then((res) => setCountT(res));
  }, []);

  const filterOutTeams = (value) => {
    const filtered = [...applications].filter(
      (x) => x.name.search(value) !== -1
    );
    setFiltered(filtered);
  };

  const sortLowest = () => {
    const sorted = [...applications].sort((a, b) => a.count - b.count);
    setFiltered(sorted);
  };

  const sortHighest = () => {
    const sorted = [...applications].sort((a, b) => b.count - a.count);
    setFiltered(sorted);
  };

  return (
    <Flex
      paddingTop={[250, 150, 120]}
      direction="column"
      justify="center"
      align="center"
    >
      <Unique countT={countT}/>
      <Counter count={count} />
      <Teams
        applications={applications}
        filtered={filtered}
        sortLowest={sortLowest}
        sortHighest={sortHighest}
        filterOutTeams={filterOutTeams}
      />
    </Flex>
  );
}
