import React from 'react'
import { Box } from '@chakra-ui/react'

import { AiOutlineClose, AiOutlineMenuFold } from 'react-icons/ai'

export default function MenuButton({ toggle, isOpen }) {
    return (
        <Box display={{ base:"block", md: "none" }}  onClick={toggle}>
            {isOpen ? <AiOutlineClose size={26}/> : <AiOutlineMenuFold size={26} />}
        </Box>
    )
}
