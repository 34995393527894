import React, { useState, useRef } from 'react';
import { MdFileUpload } from 'react-icons/md';
import { BsTrash, BsFiles } from 'react-icons/bs';
import nodata from '../../static/images/gone.svg';

import styles from '../../styles/file.module.css';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 25000000;
const KILO_BYTES_PER_BYTE = 1000;

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

export default function FileUpload({
  label = '',
  updateFileCb,
  maxFileSize = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) {
  const inputField = useRef(null);
  const [files, setFiles] = useState({});

  const handleUploadBtnClick = () => {
    inputField.current.click();
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFileCb(updatedFiles);
    }
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSize) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

  const callUpdateFileCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFileCb(filesAsArray);
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFileCb({ ...files });
  };

  return (
    <>
      <section
        style={
          otherProps.cv ? { border: '1px solid white', borderRadius: 2 } : {}
        }
      >
        <p className={styles.dragText}>
          Povucite i otpustite datoteke bilo gdje unutar kutije:
        </p>
        <button
          type="button"
          className={styles.buttonInput}
          onClick={handleUploadBtnClick}
        >
          <MdFileUpload className={styles.icon} />
          <span> Prenesi {otherProps.multiple ? 'datoteku' : 'datoteke'}</span>
        </button>
        <input
          type="file"
          ref={inputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
          className={styles.fileInput}
        />
      </section>
      <article className={styles.fileShell}>
        <span>Predani dokumenti:</span>
        <div className={styles.uploadedWrapper}>
          {Object.keys(files).length === 0 ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: 20,
              }}
            >
              <img alt="no documents" src={nodata} style={{ width: 200 }} />
              <p style={{ padding: 20, fontSize: 18, textAlign: 'center' }}>
                Izgleda da nisi predao ni jedan dokument. Klikni na{' '}
                <span style={{ fontWeight: 'bold', color: '#c5272f' }}>
                  Predaj dokumente
                </span>{' '}
                ili povuci dokumente u dio za predaju
              </p>
            </div>
          ) : (
            <>
              {Object.keys(files).map((fileName, index) => {
                let file = files[fileName];
                let isImageFile = file.type.split('/')[0] === 'image';
                return (
                  <div className={styles.imageSection}>
                    {isImageFile ? (
                      <img
                        className={styles.imagePreview}
                        src={URL.createObjectURL(file)}
                        alt={`file preview ${index}`}
                      />
                    ) : (
                      <div className={styles.iconPreview}>
                        <BsFiles size={36} />
                      </div>
                    )}
                    <div>
                      <span className={styles.imageText}>{file.name}</span>
                      <aside>
                        <span className={styles.imageSize}>
                          {convertBytesToKB(file.size)} kb
                        </span>
                      </aside>
                    </div>
                    <BsTrash
                      size={24}
                      className={styles.deleteIcon}
                      onClick={() => removeFile(fileName)}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </article>
    </>
  );
}
