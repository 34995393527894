import React, { lazy, useState } from 'react';

import { Text, Flex, Button, Input, useDisclosure } from '@chakra-ui/react';

const Popup = lazy(() => import('./Popup'));

export default function Teams({ applications, sortLowest, sortHighest, filterOutTeams, filtered }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [team, setTeam] = useState('');
  const [sign, setSign] = useState([])

  const showApplications = (title) => {
    setTeam(title)
    fetch('/api/overview/get_admissions/', {
        method:'POST',
        headers: {
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Authorization": localStorage.getItem("access")
        },
        body: JSON.stringify({
            team: title
        })
    })
    .then(res => res.json())
    .then(res => setSign(res))
    onOpen();
  };

  return applications.length !== 0 ? (
    <Flex
      flexWrap="wrap"
      bg="#EAEAEA"
      color="black"
      border="1px solid white"
      padding={5}
      borderRadius={10}
      boxShadow="5px 5px 15px 5px rgba(255,255,255,0.25)"
      flexDir="column"
      justify="center"
      align="center"
    >
      <Flex flexWrap='wrap'>
        <Text marginTop={4}>Filtriraj tim:</Text>
        <Input
          width={250}
          margin={2}
          borderRadius={0}
          variant="filled"
          placeholder='Upiši ime tima'
          borderBottom='1px solid black'
          bg='#EAEAEA'
          _hover={{
            bg: '#EAEAEA',
          }}
          _focus={{
              bg:'#EAEAEA',
              border:'None',
              borderBottom: '1px solid black'
          }}
          onChange={(value) => filterOutTeams(value.target.value)}
        />
        <Button variant="filter" onClick={sortLowest}>Sortiraj po najmanje prijava</Button>
        <Button variant="filter" onClick={sortHighest}>Sortiraj po najviše prijava</Button>
      </Flex>
      <Flex align='center' justify='center' flexWrap='wrap'>
        {filtered.map((x) => {
          return (
            <>
              <Text
                fontSize={16}
                border="1px solid grey"
                borderRadius={5}
                margin={2}
                padding={5}
                textAlign="center"
                boxShadow="2px 2px 15px 2px rgba(0,0,0,0.42)"
                onClick={() => showApplications(x.name)}
                _hover={{
                  cursor: 'pointer',
                }}
              >
                {x.name}:<br /> {x.count} prijava
              </Text>
            </>
          );
        })}
      </Flex>
      <Popup isOpen={isOpen} onClose={onClose} title="Sve prijave za tim: " team={team} admissions={sign} />
    </Flex>
  ) : null;
}
