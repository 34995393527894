export const TeamsDataList = [
  {
    title: 'LUMEN Business',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863208/BUSINESS_i9tnal.webp',
    description: `
    <p> 
      Voliš timski rad, a poslovna komunikacija ti je drugo ime? 
      Onda je tim Business pravi izbor za tebe! Već 20. godinu zaredom, tim Business
      provodi/organizira natjecanje koje okuplja najbolje umove i strastvene
      studente, a kao član našeg tima imat ćete priliku uspostaviti kontakte s
      predstavnicima poduzeća, upoznati druge natjecatelje, ali i (druge)
      proaktivne članove eSTUDENTa.
      <br />
      <br />
      Ono što nas izdvaja jest činjenica da nismo uvijek "strogo poslovni". Naš
      tim cijeni kreativnost i zabavu te se uvijek trudimo pružiti prostor za
      njih. Rad u timu Business nije samo profesionalno iskustvo, već i prilika
      za stvaranje nezaboravnih trenutaka i upoznavanje novih prijatelja.
      <br />
      <br />
      Pridružite nam se i postanite dio tima koji ostvaruje velike stvari!
      Odaberite BUSINESS i zajedno održimo još jedno uspješno natjecanje.
      <br />
      <br />
      Za bilo kakva pitanja slobodno se javite voditeljicama:
      <br />
       Hani (hana.gregurincic@estudent.hr) i
       <br />
       Magdaleni (magdalena.tolj@estudent.hr)
      <br />
    </p>`,
  },
  {
    title: 'LUMEN Data science',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863208/DATA_efyrvx.webp',
    description: `   
    <p>
      Ako si timski igrač, kreativan si i organizacija ti ide kao od šale, onda
      je naš tim pravo mjesto za tebe! Uz zabavnu i novu ekipu imat ćeš priliku
      usavršiti svoje komunikacijske i organizacijske vještine te se povezati s
      poslovnim svijetom. Bavit ćemo se organizacijom LUMEN Data Science
      natjecanja, a ti ne moraš znati baš ništa o data scienceu. 😉
    </p>
    <br />
    <p>
      Zajedno ćemo provesti projekt od početka do kraja i ponosno gledati svoja
      postignuća. Prijavi se u naš tim i postani organizator najvećeg
      studentskog natjecanja u dubinskoj analizi velikih količina podataka u
      Hrvatskoj!
    </p>
    <br />
    <p>
      Za sva pitanja možeš se javiti voditeljicama:
      <br />Martina Galović (martina.galovic@estudent.hr) <br />Tea Gusić
      (tea.gusic@estudent.hr)
    </p>`,
  },
  {
    title: 'LUMEN Engineering',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863208/ENG_prn8rm.webp',
    description: `
    <p>
      Želiš biti dio projektnog tima i organizirati veliko natjecanje?
      Inovativna si osoba i želiš izgraditi odnos s potencijalnim poslodavcima?
      Pridruži nam se, razvij organizacijske i komunikacijske vještine te
      sudjeluj u organizaciji jedinstvenog natjecanja u Hrvatskoj u izradi
      praktičnih rješenja u području elektrotehnike. Ako si se pronašao/la u
      ovome naš tim <b>LUMEN Engineering</b> savršen je odabir za tebe! 🤩 
    </p>
    <br />
    <p>
      P.S. Znanje o elektrotehnici nije uvjet, a čeka te puno zabave i eSTUDENT
      druženja! 🥳
    </p>
    <br />
    <p>
      Za sva dodatna pitanja javi se voditeljima,<br />
      Marini (marina.matesic@estudent.hr) i<br />Josipu (josip.maradin@estudent.hr)
    </p>`,
  },
  {
    title: 'LUMEN Development',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863208/DEVELOP_r7znaf.webp',
    description: `
    <p>
      Želiš sudjelovati u organizaciji velikog projekta? Odvaži se postati dio
      LUMEN Developmenta i pomozi nam u organizaciji najvećeg studentskog
      natjecanja u izradi web i mobilnih aplikacija. Surađivat ćeš s raznim IT
      kompanijama i samim natjecateljima te organizirati radionice i
      konzultacije za natjecanje i pri tome stjecati nove organizacijske i
      komunikacijske vještine, a očekuje te i puno nezaboravnih eSTUDENT
      druženja koja ne želiš propustiti. 🤗
    </p>
    <br />
    <p>
      P.S. Bez brige, ne moraš znati programirati da bi nam se pridružio i
      obogatio svoj CV nezaboravnim iskustvom!
    </p>
    <br />
    <p>Ako imaš pitanja, slobodno se javi!</p>
    <br />
    <p>
      Matea Bušić, voditeljica (matea.busic@estudent.hr) <br />Andrej Lukić,
      voditelj (andrej.lukic@estudent.hr)
    </p>`,
  },
  {
    title: 'Dobrotvorne i ekološke aktivnosti',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1663955025/DEA_oat4el.webp',
    description: `   
    <p>
      Ako je <b>humanitarni rad</b> te <b>ljubav prema prirodi</b> nešto što te
      motivira i ispunjava, nije ti potrebna daljnja potraga jer tu je tim
      <b>Dobrotvorne i ekološke aktivnosti</b>.
    </p>
    <br />
    <p>
      Poduzetni humanitarac, Nepotrebno je nekome potrebno te Zeleni eMJESEC tri
      su projekta koja se provode kroz godinu, a članovima tima pružaju
      mogućnost razvoja empatije prema potrebitim ljudima i okolišu, no uz to i
      odgovornost organizacije te uspješnog provođenja projekata, poslovne
      suradnje te rad bok uz bok s proaktivnim, maštovitim ljudima sa sličnim
      interesima.
    </p>
    <br />
    <p>Jedva te čekamo upoznati!</p>
    <br />
    <p>
      Za dodatna pitanja, slobodno piši voditeljicama,
      <br />Dori (dora.dubravac@estudent.hr) i<br />
      Krešimiru (kresimir.kelcec.suhovec@estudent.hr)
    </p>`,
  },
  {
    title: 'Društveno odgovorno poslovanje',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863208/DOP_ymkzjf.webp',
    description: `   
     <p>
      Ako želiš stupiti u kontakt i izgraditi odnos s budućim poslodavcima,
      razviti svoje komunikacijske vještine te upoznati zanimljive ljude, onda
      je <b>Društveno odgovorno poslovanje </b>pravo mjesto za tebe.
    </p>
    <br />
    <p>
      Kroz svoja tri projekta
      <b>(CSR in Spotlight, Zlatni indeks i Kopačku solidarnosti)</b> DOP tim
      promovira vrijednosti društveno odgovornog poslovanja te studentima otvara
      mnoga vrata u poslovnom svijetu i pomaže u iskorištavanju svog
      potencijala.
    </p>
    <br />
    <p>
      Uz sve ovo, DOP ti nudi sjajnu priliku za stjecanje novih prijateljstva i
      nezaboravne uspomene. Prijavi se te postani i ti član DOP tima.
      </p>
      <br />
      <p>
      Za sva dodatna pitanja, slobodno se javi voditeljima <br />
      Josipu (josip.golubic@estudent.hr) i<br />
      Ivani (ivana.sertic@estudent.hr).
      </p>`,
  },
  {
    title: 'Popularizacija znanosti',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863209/POPZNAN_huvc4n.webp',
    description: `    
    <p>
      Mi smo tim koji okuplja studente iz različitih struka sa
      <b>zajedničkim ciljem promoviranja znanosti i zdravog načina života</b>
      svojoj okolini. <b>Znanost za sve</b> i <b>Brocoola</b> naši su načini
      populariziranja znanosti među mladima. Ne promoviramo samo znanost nego i
      timski rad, uvažavanje tuđih ideja i maštovitost među članovima. Ako u
      idućoj akademskoj godini želiš steći <b>nova prijateljstva</b> i
      <b>iskustva</b> te izbrusiti svoje
      <b>komunikacijske i organizacijske vještine</b>, ovo je pravi tim za tebe!
    </p>
    <br />
    <p>
      Za sva dodatna pitanja možeš se javiti voditeljici Tonki
      (tonka.rimac@estudent.hr). <br />
    </p>`,
  },
  {
    title: 'Šapa',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1663954551/SH_fhucam.webp',
    description: `
    <p>
      Ako si osoba koja <b>uvijek zastane da pomazi psa</b> ili koja
      <b>neuspješno pokušava dozvati mačke</b>, pronašao/la si svoje mjesto!🐶😺
    </p>
    <br />
    <p>
      Imat ćeš priliku biti dio humanitarnog i ekološkog tima čija je primarna
      svrha <b>pomoć životinjama</b>, a sve to kroz razne projekte i, naravno,
      smijeh i zabavu!
    </p>
    <br />
    <p>
      Šapa će ti pružiti iskustvo <b>Project Managementa</b>, a surađivat ćeš i
      s raznim poduzećima i udrugama, kao i ostalim eSTUDENT timovima. Ako želiš
      saznati više, javi se voditeljici Niki (nika.grujic@estudent.hr). Veselimo
      se tvojoj prijavi!😉
    </p>`,
  },
  {
    title: 'Event management',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863208/EM_b9u8w9.webp',
    description: `    
    <p>
      Ako si ljubitelj noćnog života, zabavna i otvorena osoba, ona osoba koja
      nudi unikatne ideje za izlaske, voliš dobar brainstorming, potičeš ekipu
      da proba nešto novo i zanima te kako izgleda organizacija evenata — Event
      Management je pravi tim za tebe!
    </p>
    <br />
    <p>
      Event Management organizira casual druženja, partye, board game večeri,
      pub kvizove, team buildinge te sva ostala druženja unutar udruge. Brinemo
      se da svi članovi upoznaju i zabave! A kako bi to sve dokumentirali, imamo
      i instagram profil na koji objavljujemo memeove i lude slike iz izlazaka.
      Pridruživanjem svima najdražem timu ćeš naučiti organizirati evente,
      razviti brojne vještine i sjajno se zabaviti!
    </p>
    <br />
    <p>
      Ako imaš kakvih pitanja slobodno se javi voditeljima <br />Karlu
      (karlo.franic@estudent.hr) i <br />Zvonimiru(zvonimir.sculac@estudent.hr)
    </p>`,
  },
  {
    title: 'Evaluacije',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863208/EVAL_wjcmct.webp',
    description: `
    <p>
      Ako ti je stalo da se svaki član tvog društva osjeća ugodno, sretno i
      zadovoljno, pridruži se <b>Evaluacijama</b>! Zajedno ćemo brinuti o
      zadovoljstvu članova Udruge, izradom i analizom <b>anketa</b>,
      objavljivanjem internog <b>Newslettera</b> i provedbom nezaboravnih
      "Bonding" <b>radionica</b> za članove Udruge. Uz sve to, u sklopu projekta
      <b>It Starts With You</b>, brinemo i o <b>mentalnome zdravlju</b> šire
      studentske zajednice, kreiramo inspirativan sadržaj i organiziramo
      edukativne radionice sa stručnjacima kako bismo podržali i osnažili sve
      nas! Pridruži se našem timu jer ćemo zajedno razvijati timske vještine,
      stvarati nevjerojatne uspomene i predobro se zabaviti tijekom cijele
      godine!
    </p>
    <br />

    <br />
    <p>
      Za sva pitanja, obrati se voditeljicama:<br />
      Luciji Lovrić (lucija.lovric@estudent.hr) i<br />Heleni Grubačević
      (helena.grubacevic@estudent.hr).
    </p>`,
  },
  {
    title: 'Informacijske tehnologije',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863208/IT_s2ahrl.webp',
    description: `
    <p>
      Hello world! Zanima te svijet IT-ja, želiš pokazati svoje znanje i steći
      novo? Na pravom si mjestu! Kao dio tima Informacijske tehnologije, pružat
      ćeš tehničku podršku svim projektima koje eSTUDENT provodi. To znači da
      ćeš izrađivati forme, raditi promjene na web-stranici te razvijati i
      unaprijeđivati aplikacije koje Udruga koristi. Sudjelovanjem na
      edukativnim radionicama razvit ćeš i svoje komunikacijske i organizacijske
      vještine. A kad ti zatreba malo predaha, druženja su zagarantirana! Ne
      propusti ovu super priliku za novo studentsko iskustvo. Prijavi se i
      postani dio našeg IT tima!
    </p>
    <br />
    <p>
      Slobodno nam se javi za sva pitanja!<br />voditeljica Antea Šolić -
      antea.solic@estudent.hr<br />voditeljica Dora Baričević -
      dora.baricevic@estudent.hr<br />voditelj Matija Radović -
      matija.radovic@estudent.hr
    </p>`,
  },
  {
    title: 'Legal Team',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863208/LEGAL_hnkupw.webp',
    description: `
    <p>
      Svaka organizacija treba kvalitetan pravni odjel kako bi pravno djelovanje
      bilo transparentno i u skladu sa zakonom. U eSTUDENTu imaš priliku naučiti
      sve što te čeka u struci. Izbrusi svoje pravne vještine u pisanju pravnih
      akata, sudjeluj u organizaciji Legal Weeka gdje imaš priliku ostvariti
      suradnju s poznatim odvjetničkim društvima, sucima i ostalim pravnicima,
      upotpuni svoj CV i iskoristi šansu da konačno objasniš ljudima što je
      GDPR. Osim pravnih, imat ćeš prilike poboljšati organizacijske,
      komunikacijske i druge vještine, steći nova prijateljstva i biti dio
      nezaboravnih druženja.
    </p>
    <br />
    <p>
    Za sva pitanja možeš se javiti voditeljima <br /> Lovri
    (lovro.milinkovic@estudent.hr) i <br />Katarini (katarina.palac@estudent.hr).
    </p>`,
  },

  {
    title: 'Računovodstvo i financije',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863209/RIF_valjd8.webp',
    description: `    
    <p>
      U svakoj je organizaciji važno imati tim ljudi koji brine o financijskim i
      računovodstvenim poslovima. Naš tim nudi ti priliku steći iskustvo u
      vođenju financija studentske udruge i usavršavanje organizacijskih i
      komunikacijskih vještina radom na projektima iz područja računovodstva i
      financija. Bez brige, osim posla, u eSTUDENTu iskusiti ćeš i mnogo
      nezaboravnih druženja i partyja. Ukoliko želiš obogatiti svoje studentsko
      iskustvo i smatraš se ambicioznom osobom sklonom timskom radu i stjecanju
      novih prijateljstava, nemoj puno razmišljati i prijavi se u naš tim!
    </p>
    <br />
    <p>
      Za sva pitanja, slobodno se javi voditeljima:<br />Tomislavu
      (tomislav.cazin@estudent.hr) i<br />
      Irmi (irma.franciskovic@estudent.hr)
    </p>`,
  },
  {
    title: 'Marketing',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863208/MKT_ydojr1.webp',
    description: `
      <p>
      Provodiš puno vremena na društvenim mrežama, no oduvijek te zanimalo kako
      to sve izgleda iza kulisa? Vizualan si tip i zanima te izrada kreativnog
      sadržaja za Instagram i Facebook? Onda je tim Marketing pravo mjesto za
      tebe jer mi vodimo društvene mreže Udruge i smišljamo kampanje za sve
      projekte.
    </p>
    <br />
    <p>
      Kao član Marketinga stjeći ćeš mnoga znanja iz područja marketinga te ćeš
      imati priliku upoznati se s ostatkom Udruge.
    </p>
    <br />
    <p>
      Za sva pitanja možeš se javiti voditeljicama:<br />Doris Atlija
      (doris.atlija@estudent.hr), <br />Anamarija Kristina Ćurak (anamarija.kristina.curak@estudent.hr), <br />Katarina Tufeković
      (katarina.tufekovic@estudent.hr) i <br /> Lucia Zadravec
      (lucia.zadravec@estudent.hr)
    </p>
    <br />
    <p>Veselimo se tvojoj prijavi!</p>`,
  },
  {
    title: 'Multimedija',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863208/MULTIM_j1yvav.webp',
    description: `
      <p>
      Bez obzira staješ li prvi put iza kamere ili si već multimedijski veteran,
      tim multimedija te zove! Želiš se okušati u svijetu fotografije, snimanja,
      montaže, zvuka i animacije, a uz sve to i odlično zabaviti na brojnim
      druženjima i eventima?
    </p>
    <br />
    <p>
      Samo te jedan korak dijeli od toga da postaneš maher iza fotoaparata,
      stoga se prijavi i započni svoju eSTUDENT avanturu! :D
    </p>
    <br />
    <p>
      P. S. Predznanje i oprema nisu uvjet, samo volja i želja za radom
      <br />
    </p>

    <p>
      Za sva pitanja stojimo ti na raspolaganju:<br />Karlo Cvitković
      (karlo.cvitkovic@estudent.hr), <br />Katarina Paradžik
      (katarina.paradzik@estudent.hr) i<br />
      Vid Veselko (vid.veselko@estudent.hr)
    </p>`,
  },
  {
    title: 'Vizualne komunikacije',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863209/VK_rssl3w.webp',
    description: `    
    <p>
      Voliš se kreativno izražavati i privlači te spoj estetike s
      funkcionalnošću? Ako se dobro snalaziš s radom na kompjuteru, imaš smisla
      za kompoziciju i želiš steći ili unaprijediti znanje u grafičkim
      programima, VK je pravi izbor za tebe! Iskusit ćeš rad grafičkog
      dizajnera, suočiti se s izazovima iz kojih ćeš narasti te biti u
      kreativnom i produktivnom okruženju i dobro se zabaviti.
    </p>
    <br />
    <p>
      Za pitanja se javi voditeljima:<br />Petra Crvelin
      (petra.crvelin@estudent.hr), <br />Laura Grbin (laura.grbin@estudent.hr),
      <br />Bruna Hrboka (bruna.hrboka@estudent.hr) i <br />Luka Ljuban
      (luka.ljuban@estudent.hr).
    </p>`,
  },
  {
    title: 'Public Relations',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863209/PR_wfz613.webp',
    description: `
    <p>
      Želiš li naučiti kako pisati kvalitetne članke i zanimljive blogove?
      Kreativna si osoba koja se s lakoćom verbalno izražava? Zanima te kako
      tvrtke provode službenu komunikaciju i odnose s javnošću? U slučaju
      potvrdnih odgovora, tvoje želje može ostvariti PR tim eSTUDENTa! Postani
      dijelom našeg tima i nauči se timskom radu, sudjelovanju u organizaciji
      projekata na razini Udruge i korištenju alata PR i marketinške struke.
    </p>
    <br />
    <p>
      Sva dodatna pitanja slobodno uputi voditeljima: <br />Jeleni Bekić
      (jelena.bekic@estudent.hr),<br />
      Lari Kovačević (lara.kovacevic@estudent.hr) i <br />Ziti Treščec
      (zita.trescec@estudent.hr).s
    </p>`,
  },
  {
    title: 'STARTER',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863209/STARTER_rqipqw.webp',
    description: `     
    <p>
      Ako želiš naučiti kako izgleda savršeni životopis i upustiti se u
      ispravljanje tuđih, već imaš jedan razlog da se prijaviš u ovaj tim.
      Sudjelovat ćeš i u organizaciji posjeta poznatim poduzećima te planiranju
      velikih projekata koji povezuju poslodavce i studente. Imat ćeš priliku
      biti u izravnom kontaktu s poduzećima, prosljeđivati njihove oglase i tako
      savladati pravila poslovne komunikacije. Izbrusi svoje komunikacijske i
      organizacijske vještine, otkrij tajne poslovnog svijeta te postani dio
      STARTER tima. :)
    </p>
    <br />
    <p>Ako te još nešto zanima, pošalji nam poruku!</p>
    <br />
    <p>
      Andrea Miloš, voditeljica (andrea.milos@estudent.hr), <br />Simona
      Marušić, voditeljica (simona.marusic@estudent.hr), <br />Ivan Vuković,
      voditelj (ivan.vukovic@estudent.hr), <br />Antonio Ivančić, voditelj
      (antonio.ivancic@estudent.hr)
    </p>`,
  },

  {
    title: 'Varaždin',
    icon: 'https://res.cloudinary.com/estudent-hr/image/upload/v1631863209/VZ_frnaor.webp',
    description: `    
    <p>
      Društvena si osoba otvorena novim poznanstvima, timski igrač koji se ne
      boji izazova, a povrh toga humanog duha željnog novih znanja?
    </p>
    <br />
    <p>U tom slučaju eSTUDENT mreža Varaždin pravi je izbor za tebe!</p>
    <br />
    <p>
      Uključi se u Mrežu izvrsnosti i saznaj iz prve ruke kako se organiziraju
      projekti i društvena događanja i svoju ulogu u tome. Osim toga, moći ćeš
      prisustvovati na tematski različitim događajima, upoznati mnoštvo novih i
      zanimljivih ljudi te stupiti u kontakt s brojnim uspješnim poduzećima.
    </p>
    <br />
    <p>
      Veselim se upoznati te.<br />Za više detalja možeš kontaktirati
      voditeljice <br />Anu Šimunko (ana.simunko@estudent.hr), <br />Dora Žegerc
      (dora.zegerc@estudent.hr)  i <br />Mirna Šimudvarac
      (	mirna.simudvarac@estudent.hr)
    </p>`,
  },
];
