import React from 'react'
import { Box, Link } from '@chakra-ui/layout'

export default function Logo(props) {
    return (
        <Box {...props}>
            <Link fontSize="lg" fontWeight="bold" href='/'>
                <span style={{ color:'#c5272f' }}>e</span>PRISTUPNI
            </Link>
        </Box>
    )
}
