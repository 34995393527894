import React from 'react';

import { Text, Flex } from '@chakra-ui/react';

export default function Counter({ count }) {
  return (
    <Flex
      justify="space-around"
      direction="column"
      padding={5}
      w="80%"
      bg="#EAEAEA"
      color="black"
      border="1px solid white"
      borderRadius={10}
      boxShadow="5px 5px 15px 5px rgba(255,255,255,0.25)"
      margin={10}
    >
      <Text padding={3} fontSize="24">
        Prijava za prvu formu: {count.first_form}
      </Text>
      <Text padding={3} fontSize="24">
        Završene prijave: {count.second_form}
      </Text>
      <Text padding={4} fontSize="24">
        Broj studenata koji su završili prijavu: {count.student_number}
      </Text>
    </Flex>
  );
}
