import React from 'react'
import parse from 'html-react-parser'

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Image,
    Button,
    Text
} from "@chakra-ui/react"

function Popup({ isOpen, onClose, title, icon, description }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={true} allowPinchZoom={true} >
            <ModalOverlay />
            <ModalContent bg='black' border='1px solid #c5272f' maxWidth={[335, 450, 550]}>
                <ModalHeader color='white' textAlign='center'>{title}</ModalHeader>
                <ModalCloseButton color='white' _focus={{ border: '1px solid #c5272f' }}/>
                <Image src={icon} width={14} margin='2' />
                <ModalBody>
                    <Text color='white' textAlign='justify' textJustify='inter-word' marginBottom='2'>
                        {parse(description)}
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button variant='solid' color='white' onClick={onClose}>Zatvori</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default Popup