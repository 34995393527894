import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Button,
  Text,
  Link,
} from '@chakra-ui/react';

import { Solution } from './index'

function Popup({ isOpen, onClose, title, team, admissions }) {

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={true}
      allowPinchZoom={true}
    >
      <ModalOverlay />
      <ModalContent
        bg="white"
        border="1px solid black"
        boxShadow="2px 2px 15px 2px rgba(0,0,0,0.42)"
        maxW="95vw"
      >
        <ModalHeader textAlign="center">
          {title} {team}
        </ModalHeader>
        <ModalCloseButton _focus={{ border: '1px solid black' }} />
        <ModalBody
        display='flex'
        flexWrap='wrap'
        align='center'
        justify='center'
        >
          {admissions.map((x) => {

            return (
              <Flex
                flexDir="column"
                textAlign="center"
                border="1px solid black"
                padding={5}
                borderRadius="5"
                boxShadow="2px 2px 15px 2px rgba(0,0,0,0.42)"
                margin={10}
                align='center'
              >
                <Text textAlign='center' fontWeight='bold' fontSize={20}>{x.user_id}</Text>
                <Text textAlign='center' fontSize={18} fontWeight='extrabold'>{x.team_id}</Text>
                <Solution solution={x.case_solution}/>
                <Link href={x.drive_url} maxWidth='30ch' textDecor="underline" textAlign='center'>
                  {x.drive_url}
                </Link>
              </Flex>
            );
          })}
        </ModalBody>
        <ModalFooter>
          <Button variant="solid" color="white" onClick={onClose}>
            <Text>Zatvori</Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default Popup;
