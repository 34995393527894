import React, { useEffect, useState } from 'react';

import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Button,
  Text,
  useToast,
} from '@chakra-ui/react';
import { IoMdCheckmark } from 'react-icons/io';
import { AiOutlineClose } from 'react-icons/ai';

export default function CandidateList({ lap }) {
  const [applications, setApplications] = useState([]);

  const toast = useToast();
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    fetch('/api/dashboard/get_all_applications/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('access'),
      },
    })
      .then((res) => res.json())
      .then((res) => setApplications(res));
  }, []);

  const selectMember = (user, team, choice) => {
    fetch('/api/dashboard/accept_member/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('access'),
      },
      body: JSON.stringify({
        uuid: user,
        team: team,
        choice: choice,
      }),
    }).then((res) => {
      if (res.status === 201) {
        res.json().then((x) => {
          toast({
            description: x.sucess,
            title: 'Član prihvaćen',
            status: 'success',
            isClosable: true,
          });
        });
      } else {
        res.json().then((x) => {
          toast({
            description: x.error,
            title: res.status,
            status: 'error',
            isClosable: true,
          });
        });
      }
    });
  };

  const rejectMember = (user, team, choice) => {
    fetch('/dashboard/reject_member/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('access'),
      },
      body: JSON.stringify({
        uuid: user,
        team: team,
        choice: choice,
      }),
    }).then((res) => {
      if (res.status === 201) {
        res.json().then((x) => {
          toast({
            description: x.success,
            title: 'Član odbijen',
            status: 'info',
            isClosable: true,
          });
        });
      } else {
        res.json().then((x) => {
          toast({
            description: x.error,
            title: res.status,
            status: 'error',
            isClosable: true,
          });
        });
      }
    });
  };

  return applications.length !== 0 ? (
    <Flex align="center">
      <Table variant="dashboard" wordBreak="normal">
        <Thead borderBottom="1px solid grey">
          <Tr>
            <Td>Ime</Td>
            <Td>Prezime</Td>
            <Td>Fakultete</Td>
            <Td>1. odabir</Td>
            <Td>2. odabir</Td>
            <Td>3. odabir</Td>
          </Tr>
        </Thead>
        <Tbody>
          {applications.map((x) => {
            return !x.accepted_team ? (
              <Tr>
                <Td>{x.first_name}</Td>
                <Td>{x.last_name}</Td>
                <Td>{x.university}</Td>
                <Td>
                  <Flex direction="column" justify="center" align="center">
                    <Text>{x.first_choice}</Text>
                    <Flex>
                      <Button
                        variant="selection"
                        disabled={
                          lap.lap_number === 1 &&
                          lap.active &&
                          x.first_choice_accepted === null && user.leads === x.first_choice
                            ? false
                            : true
                        }
                        onClick={() =>
                          selectMember(
                            x.id,
                            x.first_choice,
                            'first_choice_accepted'
                          )
                        }
                        margin={2}
                      >
                        <IoMdCheckmark size={20} />
                      </Button>
                      <Button
                        variant="selection"
                        disabled={
                          lap.lap_number === 1 &&
                          lap.active &&
                          x.first_choice_accepted === null && user.leads === x.first_choice
                            ? false
                            : true
                        }
                        onClick={() =>
                          rejectMember(
                            x.id,
                            x.first_choice,
                            'first_choice_accepted'
                          )
                        }
                        margin={2}
                      >
                        <AiOutlineClose size={20} />
                      </Button>
                    </Flex>
                  </Flex>
                </Td>
                <Td>
                  <Flex direction="column" justify="center" align="center">
                    <Text>{x.second_choice}</Text>
                    <Flex>
                      <Button
                        variant="selection"
                        disabled={
                          lap.lap_number === 2 &&
                          lap.active &&
                          x.second_choice_accepted === null && user.leads === x.second_choice
                            ? false
                            : true
                        }
                        onClick={() =>
                          selectMember(
                            x.id,
                            x.second_choice,
                            'second_choice_accepted'
                          )
                        }
                        margin={2}
                      >
                        <IoMdCheckmark size={20} />
                      </Button>
                      <Button
                        variant="selection"
                        disabled={
                          lap.lap_number === 2 &&
                          lap.active &&
                          x.second_choice_accepted === null && user.leads === x.second_choice
                            ? false
                            : true
                        }
                        onClick={() =>
                          rejectMember(
                            x.id,
                            x.second_choice,
                            'second_choice_accepted'
                          )
                        }
                        margin={2}
                      >
                        <AiOutlineClose size={20} />
                      </Button>
                    </Flex>
                  </Flex>
                </Td>
                <Td>
                  <Flex direction="column" justify="center" align="center">
                    <Text>{x.third_choice}</Text>
                    <Flex>
                      <Button
                        variant="selection"
                        disabled={
                          lap.lap_number === 3 &&
                          lap.active &&
                          x.third_choice_accepted === null && user.leads === x.third_choice
                            ? false
                            : true
                        }
                        onClick={() =>
                          selectMember(
                            x.id,
                            x.third_choice,
                            'third_choice_accepted'
                          )
                        }
                        margin={2}
                      >
                        <IoMdCheckmark size={20} />
                      </Button>
                      <Button
                        variant="selection"
                        disabled={
                          lap.lap_number === 3 &&
                          lap.active &&
                          x.third_choice_accepted === null && user.leads === x.third_choice
                            ? false
                            : true
                        }
                        onClick={() =>
                          rejectMember(
                            x.id,
                            x.third_choice,
                            'third_choice_accepted'
                          )
                        }
                        margin={2}
                      >
                        <AiOutlineClose size={20} />
                      </Button>
                    </Flex>
                  </Flex>
                </Td>
              </Tr>
            ) : null;
          })}
        </Tbody>
      </Table>
    </Flex>
  ) : (
    <Flex align="center" justify="center">
      <Text paddingTop={100} fontSize={36}>
        Nažalost, još nema prijava za tvoj tim
      </Text>
    </Flex>
  );
}
