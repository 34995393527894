import React from 'react';

import { Text, Flex } from '@chakra-ui/react';

export default function Unique({ countT }) {
  return countT.length !== 0 ? (
    <div>
      <Flex
        flexWrap="wrap"
        bg="#EAEAEA"
        color="black"
        border="1px solid white"
        borderRadius={10}
        padding={4}
        boxShadow="5px 5px 15px 5px rgba(255,255,255,0.25)"
        flexDir="column"
        justify="center"
        align="center"
      >
        <Flex flexWrap="wrap">
          <Text fontSize={28}>Prijave po odabirima:</Text>
        </Flex>
        <Flex align="center" justify="center" flexWrap="wrap">
          {Object.keys(countT).map((x) => {
            return (
              <>
                <Text
                  fontSize={16}
                  border="1px solid grey"
                  borderRadius={5}
                  margin={2}
                  padding={5}
                  textAlign="center"
                  boxShadow="2px 2px 15px 2px rgba(0,0,0,0.42)"
                  _hover={{
                    cursor: 'pointer',
                  }}
                >
                  <Text fontSize={20} fontWeight={900} padding={2}>
                    {x}
                  </Text>
                  <Text padding={1}>
                    Prijava na prvom mjestu: {countT[x].first_choice}
                  </Text>
                  <Text padding={1}>
                    Prijava na drugom mjestu: {countT[x].second_choice}
                  </Text>

                  <Text padding={1}>
                    Prijava na trećem mjestu: {countT[x].third_choice}
                  </Text>
                </Text>
              </>
            );
          })}
        </Flex>
      </Flex>
    </div>
  ) : null;
}
